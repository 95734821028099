import React from "react";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import {
    TitleComponent,
    Title,
    Container,
    ContainerContact,
    ContainerInputs,
    Text,
    Input,
    ButtonSend,
    Line,
} from "./style";
import { BiSolidContact } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhoneOutgoing } from "react-icons/fi";
import { AiTwotoneMail } from "react-icons/ai";
import { BsPinMap } from "react-icons/bs";

export default function Contatos() {
    const { t } = useTranslation();

    return (
        <>
            <NavBar />
            <TitleComponent>
                <BiSolidContact size={35} color="#50700F" />
                <Title>{t("contact-us")}</Title>
            </TitleComponent>
            <Container>
                <ContainerContact>
                    <ul>
                        <Text>
                            <FaWhatsapp size={55} color="#50700F" />
                            whatsApp
                            <p> {t("is-a-quick-way-to-contact-us")}</p>
                            <p>
                                <a
                                    href="https://api.whatsapp.com/send?phone=5561990227396"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    (61) 99022-7396
                                </a>
                            </p>
                        </Text>
                        <Line />
                        <Text>
                            <FiPhoneOutgoing size={55} color="#50700F" />
                            {t("contact-us")}
                            <p>{t("you-can-call-anytime")}</p>
                            <p>
                                <a
                                    href="tel:+556135472977"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    (61) 3547-2977
                                </a>
                            </p>
                        </Text>
                        <Line />
                        <Text>
                            <AiTwotoneMail size={55} color="#50700F" />
                            {t("email")}
                            <p>{t("do-you-contact-us")}</p>
                            <p>
                                <a
                                    href="mailto:comercial@ecolifemeioambiente.com.br"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ fontSize: "16px" }}
                                >
                                    comercial@ecolifemeioambiente.com.br
                                </a>
                            </p>
                        </Text>
                        <Line />
                        <Text>
                            <BsPinMap size={55} color="#50700F" />
                            <p>{t("we-are-located")}</p>
                            <p>
                                SHC norte, Q. 306 Bloco C SALA 117 - Asa Norte,
                                Brasília - DF, 70745-530
                            </p>
                        </Text>
                    </ul>
                </ContainerContact>
            </Container>
            <Footer />
        </>
    );
}
